.champIcons {
    width: 48px;
    height: 48px;
    object-fit: cover;
    object-position: center center;
    clip-path: inset(5% 5% 5% 5% round 10px);
    box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 25px -4px rgba(0, 0, 0, 0.75);
}

.teamIcons {
    clip-path: inset(7% 7% 7% 7% round 10px);
}

.sumChampIcons {
    clip-path: inset(7% 7% 7% 7% round 10px);
    height: 95px;
    width: 95px;
}

.topbar {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 23px;
}

.top-bar-wrapper {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.splashArt {
    height: 85vh;
    width: 100%;
    background-size: cover;
    margin-top: 5vh;
    background-position: 50% 15%;
    transition: height 0.3s ease;
}

.text1 {
    color: white;
    font-family: myFirstFont;
    font-size: 60px;
    position: absolute;
    top: 10vh;
    left: 3vh;
    transition: font-size 0.3s ease;
}

.text2 {
    color: white;
    font-family: mySecondFont;
    font-size: 30px;
    position: absolute;
    left: 3vh;
    bottom: 12vh;
    transition: bottom 0.3s ease;

}

@media only screen and (max-width: 1536px) {
    .splashArt {
        height: 85vh;
    }

    .text1 {
        font-size: 100px;
    }

    .text2 {
        bottom: 12vh;
    }
}

@media only screen and (max-width: 1280px) {
    .splashArt {
        height: 70vh;
    }

    .text1 {
        font-size: 80px;
    }

    .text2 {
        bottom: 28vh;
    }
}

@media only screen and (max-width: 1024px) {
    .splashArt {
        height: 60vh;
    }

    .text1 {
        font-size: 60px;
    }

    .text2 {
        bottom: 38vh;
    }
}

@media only screen and (max-width: 768px) {
    .splashArt {
        height: 40vh;
    }

    .text1 {
        font-size: 40px;
    }

    .text2 {
        bottom: 58vh;
    }
}

@media only screen and (max-width: 415px) {
    .splashArt {
        height: 30vh;
    }

    .text1 {
        font-size: 40px;
    }

    .text2 {
        font-size: 20px;
        bottom: 68vh;
    }
}



@font-face {
    font-family: myFirstFont;
    src: url(../../public/PermanentMarker-Regular.ttf);
}

@font-face {
    font-family: mySecondFont;
    src: url(../../public/BreeSerif-Regular.ttf);
}



.champHeaders {
    font-family: mySecondFont;
    font-size: 1.5em;
    border-radius: 10px;
    display: inline-block;
    padding-inline: 10px;
}

.info-header{
    background-color: rgba(144, 230, 94, 0.4);
    border-radius: 10px;
    color: black;
    display: inline-block;
    padding-inline: 10px;
    backdrop-filter: blur(10px);
}

.info {
    font-family: mySecondFont;
    font-size: 12px;
    border-radius: 10px;
    display: inline-block;
    padding-inline: 10px;
}


.abilities-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.abilities {
    height: 48px;
    width: 48px;
    background-size: cover;
    border-radius: 20%;
}

.newshadow {
    box-shadow: 0px 0px 11px 12px rgba(0, 0, 0, 0.1);
    ;
}


.fade-tooltip {
    opacity: 0;
    transition: opacity 0.3s ease-in-out
  }
  
  .fade-tooltip.show {
    opacity: 1;
  }
  

  .main-text {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    display: inline-block;
    /* Additional styles for <mainText> */
  }
  
  .stats {
    font-size: 14px;
    color: #555;
    display: inline-block;
    /* Additional styles for <stats> */
  }
  
  .attention {
    color: #f00;
    display: inline-block;
    /* Additional styles for <attention> */
  }
  
  .rarity-mythic {
    font-weight: bold;
    color: #ff8c00;
    display: inline-block;
    /* Additional styles for <rarityMythic> */
  }
  
  .rarity-legendary {
    font-weight: bold;
    color: #800080;
    display: inline-block;
    /* Additional styles for <rarityLegendary> */
  }
  
  .inline{
    margin-top: 10px;
    display: inline-block;
    font-size:small;
  }

  .backgroundstyle {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20%;
}

.itemtitle{
    display: flex;
    flex-direction: row;
}

.backGroundMap{
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: auto 100%;
    margin-top: 20;
    border-radius: 10px;
}

.winStyle{
    background: linear-gradient(120deg, rgb(144, 230, 94, 1) 0%, rgb(183, 229, 155, 1) 70%, rgba(184,246,149,0) 85%);
}

.loseStyle{
    background: linear-gradient(120deg, rgb(230, 94, 94, 1) 0%, rgb(229, 155, 155, 1) 70%, rgba(246, 149, 149, 0) 85%);
}

.dropdown-list-disabled {
    pointer-events: none;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .info-icon{
    height: auto;
    width: 20px;
    filter: drop-shadow(0px 0px 3px #094701);
}


  